import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NotFound from './NotFound';
import Hero from '../promo/Hero';
import MetaProperties from '../generic/MetaProperties';
import PageHeader from '../generic/PageHeader';
import SeeAllRecipesLink from '../generic/SeeAllRecipesLink';
import StandardCollection from '../promo/collections/StandardCollection';
import StandardCollection12Promos from '../promo/collections/StandardCollection12Promos';
import { heroShape } from '../promo/shapes';
import { seasonalPromoShape } from '../generic/seasonalPromo/shapes';
import TabBar from '../generic/tabBar/TabBar';
import Tab from '../generic/tabBar/Tab';
import SeasonalPromoBanner from '../generic/seasonalPromo/SeasonalPromoBanner';
import ContentSeparator from '../generic/ContentSeparator';

/* eslint-disable global-require */
if (process.env.CLIENT) {
  require('./Page.scss');
}
/* eslint-enable global-require */

const has = thing => thing && thing.length > 0;

export const Page = ({
  allTimeFavDishes,
  hero,
  ingredients,
  recipeCollections,
  totalIngredients,
  thrownErrorFrom,
  thrownErrorStatus,
  seasonalPromo,
  foodImagesPath,
}) => (
  <>
    <MetaProperties title="Recipes" />

    <div className="error-status__wrap gel-wrap">
      {thrownErrorStatus === 404 && <NotFound thrownErrorFrom={thrownErrorFrom} />}
    </div>

    <PageHeader className="index__title" title="Recipes" />
    <TabBar>
      <Tab
        id="featured"
        href="/food/recipes#featured-content"
        entity="recipes"
        title="Featured recipes"
        selected
      >
        Featured
      </Tab>
      <Tab id="all" href="/food/recipes/a-z#featured-content" entity="recipes" title="All recipes">
        All
      </Tab>
    </TabBar>
    {hero && <Hero className="recipes-index-hero" {...hero} />}
    {has(recipeCollections) && (
      <>
        <StandardCollection12Promos
          id="recipes-index-recipeCollections"
          promos={recipeCollections}
        />
        <ContentSeparator />
      </>
    )}

    {has(allTimeFavDishes) && (
      <>
        <StandardCollection12Promos
          id="recipe-index-allTimeFavDishes"
          title="All-time favourite dishes"
          promos={allTimeFavDishes}
          maxCollectionSize={12}
        />
        <ContentSeparator />
      </>
    )}

    {has(ingredients) && (
      <>
        <StandardCollection
          id="recipe-index-ingredients"
          title="Cooking with..."
          promos={ingredients}
          maxCollectionSize={12}
        />
        <div className="see-all-recipes__wrap gel-wrap">
          <div className="gel-layout--center">
            <SeeAllRecipesLink
              body="more ingredients"
              className="recipes-index-see-more-ingredients gel-layout__item gel-1/1 gel-10/12@m"
              href="/food/ingredients"
              totalCount={totalIngredients}
            />
          </div>
        </div>
      </>
    )}

    {seasonalPromo && (
      <SeasonalPromoBanner season={seasonalPromo} foodImagesPath={foodImagesPath} />
    )}
  </>
);

Page.propTypes = {
  allTimeFavDishes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      count: PropTypes.number,
      imageSrc: PropTypes.string,
      title: PropTypes.string,
    })
  ).isRequired,
  hero: PropTypes.shape(heroShape),
  ingredients: PropTypes.arrayOf(
    PropTypes.shape({
      hasImage: PropTypes.string,
      id: PropTypes.string,
      keyRecipe: PropTypes.shape({
        id: PropTypes.string,
      }),
      title: PropTypes.string,
    })
  ).isRequired,
  seasonalPromo: PropTypes.shape(seasonalPromoShape),
  recipeCollections: PropTypes.arrayOf(
    PropTypes.shape({
      count: PropTypes.number,
      id: PropTypes.string,
      imageSrc: PropTypes.string,
      imageSrcset: PropTypes.string,
      title: PropTypes.string,
    })
  ).isRequired,
  totalIngredients: PropTypes.number,
  thrownErrorFrom: PropTypes.string,
  thrownErrorStatus: PropTypes.number,
  foodImagesPath: PropTypes.string.isRequired,
};

Page.defaultProps = {
  hero: undefined,
  totalIngredients: undefined,
  thrownErrorFrom: undefined,
  thrownErrorStatus: undefined,
  seasonalPromo: undefined,
};

export default connect(({ recipesIndexReducer, pageReducer }) => ({
  allTimeFavDishes: recipesIndexReducer.allTimeFavDishes,
  ingredients: recipesIndexReducer.ingredients,
  hero: recipesIndexReducer.hero,
  recipeCollections: recipesIndexReducer.recipeCollections,
  totalIngredients: recipesIndexReducer.totalIngredients,
  thrownErrorFrom: pageReducer.thrownErrorFrom,
  thrownErrorStatus: pageReducer.thrownErrorStatus,
  seasonalPromo: pageReducer.seasonalPromo,
  foodImagesPath: pageReducer.foodImagesPath,
}))(Page);
