import React from 'react';
import PropTypes from 'prop-types';

if (process.env.CLIENT) {
  require('./NotFound.scss'); // eslint-disable-line global-require
}

const errors = {
  default: {
    text: `Whoops! Looks like there’s been an error. While the site is going through some changes, you
    might find yourself at this page more often than we’d like. If you have landed on this page
    from a link in the BBC website, let us know and we’ll try to fix it (if not, you might want
    to check the URL for any typing mistakes).`,
    title: 'Page not found',
  },
  recipePage: {
    text: `Unfortunately, we can’t find the recipe you’re looking for. This may be because it has been
    taken down from the BBC Food website, which happens when our rights to publish a recipe
    expire. To find an alternative recipe from our collection you can search for the key
    ingredients in the search box or try one of the recommended recipes below.`,
    title: 'Recipe not found',
  },
};

const NotFound = ({ thrownErrorFrom }) => {
  const { text, title } = errors[thrownErrorFrom] || errors.default;

  return (
    <div className="recipe-404__wrapper gel-1/1">
      <div className="recipe-404-panel__wrapper">
        <div className="recipe-404-panel gel-10/12">
          <div className="recipe-404-content">
            <div className="recipe-not-found__text gel-trafalgar">{title}</div>
            <div className="gel-pica">{text}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

NotFound.propTypes = {
  thrownErrorFrom: PropTypes.string,
};

NotFound.defaultProps = {
  thrownErrorFrom: '',
};

export default NotFound;
